import React from 'react'

const TermsAndCondition = () => {
    return (
        <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg my-10">
            <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>

            <p className="mb-4">
                THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000 (“IT ACT”) AND RULES ISSUED THEREUNDER, AS APPLICABLE AND THE PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE IT ACT AND IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF APPLICABLE LAWS, INCLUDING THE CONSUMER PROTECTION (E-COMMERCE) RULES 2020, THAT REQUIRE PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS AND CONDITIONS FOR ACCESS OR USAGE OF THE WEBSITE. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
            </p>

            <p className="mb-4">
                PLEASE READ THESE TERMS AND CONDITIONS (“T&C”) CAREFULLY. BY ACCESSING, BROWSING, USING <a href="https://www.agastya-international.com" className="text-blue-500">WWW.AGASTYA-INTERNATIONAL.COM</a>, MOBILE VERSION OF THE WEBSITE OR AVAILING ANY OF THE PRODUCTS OF YASH PAKKA LIMITED (HEREINAFTER REFERRED TO AS “COMPANY” OR “US” OR “WE”) YOU INCLUDING THE PERSONS WHO BROWSES OR THE PERSONS WHO ORDERS THE PRODUCT AGREE TO BE BOUND BY ALL OF THE T&C MENTIONED HEREUNDER.
            </p>

            <h2 className="text-xl font-semibold mb-2">Eligibility</h2>
            <p className="mb-4">
                Only persons who can enter into legally binding contract under the Indian Contract Act, 1872 can use the Website and/or transact on the Website. Any minor who wishes to use or access the Website is required to conduct such transaction through their legal guardian or parents. If you represent a company, partnership firm or sole proprietorship, you shall be eligible to access and use the Website to conduct the transactions on its behalf only if you have been duly authorized by way of necessary corporate action, as may be prescribed statutorily and/or under the charter documents of such entity.
            </p>

            <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
            <p className="mb-4">
                The Company reserves the right to amend or revise the T&C at any time by uploading a revised or amended T&C on the Website with or without prior notice. The amended T&C will be effective immediately after it is uploaded on this Website. Your access or use of the Website following any such changes constitutes your acceptance to follow and You shall be bound by these T&C, as amended. The version of the T&C that will apply to your order will be those uploaded on the Website at the time you use or access or place your order. For this reason, we encourage you to review these T&C each time you access and place your order. This T&C does not alter in any way the terms or conditions of any other written agreement you may have with the Company for other products or services. If you do not agree to this T&C (including any referenced policies or guidelines), please immediately terminate your use of the Website.
            </p>

            <h2 className="text-xl font-semibold mb-2">Use of Website</h2>
            <p className="mb-4">
                You may only access the Website for lawful purposes. You are solely responsible for the knowledge of and adherence to any and all laws, rules, and regulations pertaining to your use of the Website.
            </p>

            <p className="mb-4">
                We reserve the right to block any user and permanently disable user account if we believe in our sole discretion that action of such user has resulted in breach of fair usage policy or T&C.
            </p>

            <h2 className="text-xl font-semibold mb-2">Products</h2>
            <h3 className="text-lg font-semibold mb-2">Terms of Offer</h3>
            <p className="mb-4">
                The Website offers for sale certain products (“Products”). By placing an order for the Products through the Website, you agree to the terms and conditions set forth in this T&C. The Products described on the Website, we may provide to you, are for personal use only. You may not sell or resell any of the Products, or samples thereof, you receive from us unless agreed otherwise. We reserve the right, with or without notice, to cancel or reduce the quantity of any Products to be provided to you that we believe, in our sole discretion, may result in the violation of our T&C.
            </p>

            <p className="mb-4">
                The Company may change, suspend, or discontinue the availability of any of the Products at any time, without any notice or liability. You acknowledge that the price payable in connection with the Product or any service, may be subject to change, without notice or liability.
            </p>

            <p className="mb-4">
                While describing our Products on our Website, we endeavour to be as accurate as possible. To the extent implied by applicable law, we do not warrant that the Product descriptions, colours, information or other content available on the Website are accurate, complete, reliable, current, or error-free. The Website may contain typographical errors or inaccuracies and may not be complete or updated. Such errors, inaccuracies or omissions may also relate to pricing and availability of the Product or services. Please note that the Product pictures are indicative and may not match the actual Product.
            </p>

            <h2 className="text-xl font-semibold mb-2">Customer Solicitation</h2>
            <p className="mb-4">
                By accessing the Website or placing an order or sending any information, you are communicating with the Company electronically and you agree to receive communications (including transactional, promotional and/or commercial messages) from the Company periodically and as and when required. We may communicate with you by e-mail, SMS, phone call or by posting notices on the Website or by any other mode of communication.
            </p>

            <h3 className="text-lg font-semibold mb-2">Opt Out Procedure</h3>
            <p className="mb-4">
                We provide 2 easy ways to opt out of from future Customer Solicitations:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>You may use the opt out link found in any email solicitation that you may receive.</li>
                <li>You may also choose to opt out, via sending your email by addressing to: <a href="mailto:agastyainternational0@gmail.com" className="text-blue-500">agastyainternational0@gmail.com</a></li>
            </ul>

            <h2 className="text-xl font-semibold mb-2">Proprietary Rights</h2>
            <p className="mb-4">
                All the brand names owned and licensed to the Company are exclusive property of the Company, its affiliates, partners or licensors, and is protected by laws of India, including laws governing all applicable forms of intellectual property. The Company has the proprietary rights and trade secrets in the Products. You shall not copy, reproduce, modify, duplicate, re-publish, re-sell or re-distribute any Product manufactured and/or distributed by the Company in whole or in part or in any other form whatsoever. The Company also has rights to all trademarks and trade dress and specific layouts of this webpage, including without limitation calls to action, text placement, images, technology, content, software and other materials, which appear on the Website, including its looks and feel. No trademark is granted in connection with the Products or the materials contained on the Website. The access to the Website does not authorize anyone to use any trademarks in any manner. The trademarks displayed on the Website whether registered or unregistered, are the intellectual property of the Company.
            </p>

            <h2 className="text-xl font-semibold mb-2">Tax</h2>
            <p className="mb-4">
                If you purchase any Products, you will be responsible for paying any applicable taxes in relation to such purchase.
            </p>

            <h2 className="text-xl font-semibold mb-2">Your Account</h2>
            <p className="mb-4">
                You may create and hold one user account (“Account”) only. You will be responsible for maintaining confidentiality of your account, profile, passwords, and restricting access to your computer, and you hereby accept responsibility for all activities that occur under your Account. You acknowledge that the information you provide, in any manner whatsoever, are not confidential or proprietary and does not infringe any rights of a third party in whatsoever nature. Each Account is non-transferrable and may not be sold, traded, combined, or otherwise shared with any other person or entity.
            </p>

            <h2 className="text-xl font-semibold mb-2">No Warranties</h2>
            <p className="mb-4">
                The Company makes no representations or warranties of any kind, express or implied, as to the operation of the Website or the information, content, materials, or products included on the Website. The Company does not warrant that the Website will be uninterrupted or error-free, and the Company will not be liable for any interruptions or errors.
            </p>

            <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
            <p className="mb-4">
                To the fullest extent permissible by law, the Company will not be liable for any indirect, incidental, special, consequential, or punitive damages, including lost profits, data, or goodwill, arising out of or related to your use of the Website or the Products, even if the Company has been advised of the possibility of such damages.
            </p>

            <h2 className="text-xl font-semibold mb-2">Governing Law</h2>
            <p className="mb-4">
                These T&C are governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these T&C shall be subject to the exclusive jurisdiction of the courts in India.
            </p>

            <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
            <p className="mb-4">
                If you have any questions or concerns regarding these Terms and Conditions, please contact us at: <a href="mailto:agastyainternational0@gmail.com" className="text-blue-500">agastyainternational0@gmail.com</a>.
            </p>
        </div>
    )
}

export default TermsAndCondition
